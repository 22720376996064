@charset "utf-8";

$color-white: #fff;
$color-yellow: #fb8b0e;
$color-orange: #f96430;
$color-brown: #9b7749;
$color-dark: #222222;
$color-gray: #868686;
$color-ed: #ededed;
$color-f7: #f7f7f7;

$color-primary: #fc9009;

$transition-duration: .4s;
$transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

$transition-default: $transition-duration $transition-timing-function;

$breakpoint-sp: 767px;

@mixin sp {
  @media (max-width: ($breakpoint-sp)) {
    @content;
  }
}

@mixin md {
  @media (min-width: (768px)) {
    @content;
  }
}

@mixin max($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}