@charset "utf-8";
@import 'config';
/**
 *
 *  Blog common
 *
 */

/*--------------------------------------------------------------------------
	 blog-layout
---------------------------------------------------------------------------*/


/* blog-layout
-----------------------------------------------------------------*/
.blog-layout {
    margin-top: 100px;
    margin-bottom: 100px;
}

.blog-layout__shoulder {
    position: relative;
    float: right;
    width: 30%;
}

.blog-layout__footer {
    clear: both;
    margin-top: 0;
    padding-top: 80px;
}


/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
    .blog-layout {
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .blog-layout__main {
        float: none;
        width: auto;
    }

    .blog-layout__shoulder {
        float: none;
        margin-top: 45px;
        width: auto;
    }

    .blog-layout__footer {
        padding-top: 80px;
    }
}


/*--------------------------------------------------------------------------
	 m-headline.m-headline--blog
---------------------------------------------------------------------------*/


/* m-headline m-headline--blog
-----------------------------------------------------------------*/
.blog-headline {
    padding: 0;
}

.blog-headline .blog-headline__block {
    margin-bottom: 10px;
}

.blog-headline .blog-headline__date {
    display: inline-block;
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
}


/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
    .blog-headline .blog-headline__block {
        margin-bottom: 15px;
    }

    .blog-headline .blog-headline__date {
        margin-left: 10px;
        font-size: 10px;
        font-weight: normal;
    }
}


/*--------------------------------------------------------------------------
    blog-category
---------------------------------------------------------------------------*/


/* blog-category
-----------------------------------------------------------------*/
.blog-category {
    border-top: 1px solid #e9e9e9;
}

.blog-category + .blog-category {
    margin-top: 40px;
}

.blog-category__hdg {
    padding: 22px 40px 21px;
    border-top: 1px solid #f88f16;
    background-color: #fcfcfc;
    font-size: 24px;
    color: #fb8c0d;
}

.blog-category__list {
    border-bottom: 1px solid #e9e9e9;
}

.blog-category__list a {
    display: block;
    border-top: 1px solid #e9e9e9;
    padding: 19px 40px 20px;
    font-size: 16px;

    -webkit-transition: background .4s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: background .4s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog-category__list a:focus,
.blog-category__list a:hover {
    background: #fb8c0d;
    color: #fff;
}


/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
    .blog-category__hdg {
        padding: 18px 30px 17px;
        font-size: 16px;
    }

    .blog-category__list a {
        padding: 15px 30px 16px;
        font-size: 12px;
    }
}


/*--------------------------------------------------------------------------
	 m-txt-box.m-txt-box--blog
---------------------------------------------------------------------------*/


/* m-txt-box.m-txt-box--blog
-----------------------------------------------------------------*/
.m-txt-box.m-txt-box--blog {
    font-size: 12px;
    line-height: 2;

    @include md {
        font-size: 16px;
    }

    iframe {
        // width: 100%;
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    h2.hdg,h3.hdg {
        margin-bottom: 22px;
        // color: #fb8c0d;
        font-size: 16px;

        border-bottom: 1px solid;
        line-height: (50 / 33);

        @include md {
            font-size: 32px;
            border-bottom: 2px solid;
        }
    }

    // h3 {
    //     margin-top: 20px;
    //     font-size: 16px;
    //     font-weight: 700;
    //     line-height: 2;
    // }

    a {
        text-decoration: underline;
        transition: $transition-default;

        &:hover {
            color: $color-primary;

        }
    }

    p.lead,p.img,p.note {
        font-size: 12px;
        line-height: 2;

        @include md {
            font-size: 16px;
        }
    }

    > *:not(:first-child) {
        margin-top: 2.5em;

        @include md {
            margin-top: 3.5em;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @include sp {
            width: 100%;
            height: auto;
        }
    }
}


/* media query -> sp
=================================================================*/
// @media only screen and (max-width: 767px) {
//     .m-txt-box.m-txt-box--blog h2 {
//         margin-bottom: 15px;
//         font-size: 19px;
//     }

//     .m-txt-box.m-txt-box--blog h3 {
//         margin-top: 1em;
//         font-size: 12px;
//     }

//     .m-txt-box.m-txt-box--blog p {
//         font-size: 12px;
//     }

//     .m-txt-box.m-txt-box--blog img {
//         width: 100%;
//         height: auto;
//     }

//     .m-txt-box.m-txt-box--blog > * {
//         margin-top: 2.5em;
//     }
// }


/*--------------------------------------------------------------------------
	 blog-post-list
---------------------------------------------------------------------------*/


/* blog-post-list
-----------------------------------------------------------------*/
.blog-post-list {
    margin-top: 70px;

    .m-post-list {
        .item {
            a {
                text-align: left;
            }
        }
    }
}

.blog-post-list__hdg {
    padding: 14px 40px;
    font-size: 24px;
    color: #fb8c0d;
}

.blog-post-list .item a {
    font-weight: bold;
}


/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
	.blog-post-list {
		margin-top: 35px;
	}

	.blog-post-list .item:last-child {
			border-bottom: none;
	}

	.blog-post-list__hdg {
			padding-right: 0;
			padding-left: 0;
			font-size: 17px;
	}

	.blog-post-list .item a {
			padding-right: 0;
			padding-left: 0;
	}
}


/*--------------------------------------------------------------------------
	 blog-post-link
---------------------------------------------------------------------------*/
.blog-post-link {
	margin-top: 50px;
}

.blog-post-link ul {
	text-align: center;
	letter-spacing: -.4em;
}

.blog-post-link li {
	display: inline-block;
	width: 300px;
	letter-spacing: 0;
}

.blog-post-link li + li {
	margin-left: 30px;
}

.link-is-sp {
    .mode-pc & {
        pointer-events: none;
    }
}




/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
	.blog-post-link {
		margin-top: 25px;
	}

	.blog-post-link li {
		display: block;
		width: auto;
	}

	.blog-post-link li + li {
		margin-left: 0;
		margin-top: 10px;
	}
  
  .mt-30 {
    margin-top: 30px !important;
  }
}
